import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';
import AuthorizedGuard from './components/Guards/AuthorizedGuard';

import { BASE_URL, UsersRolesAttributes } from './config/constant';

// userAccount hold the data of the current logged in User
export const renderRoutes = (routes = []) => (
    <Suspense fallback={<Loader />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        alpha="true"
                        render={(props) => (
                            <Guard>
                                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} className="scrollbar-container" />}</Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signin',
        component: lazy(() => import('./views/auth/signin/SignIn'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signup',
        component: lazy(() => import('./views/auth/signup/SignUp'))
    },

    {
        path: '*',
        layout: AdminLayout,
        routes: [
            {
                exact: true,
                guard: AuthGuard,
                path: '/dashboard',
                component: lazy(() => import('./views/dashboard'))
            },
            {
                exact: true,
                guard: AuthGuard,
                path: '/results',
                component: lazy(() => import('./views/results'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.CONSULT_STATS, UsersRolesAttributes.CONSULT_DETAILED_STATS] }),
                path: '/statistics',
                component: lazy(() => import('./views/statistics/pages/GeneralStats'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.CONSULT_STATS, UsersRolesAttributes.CONSULT_DETAILED_STATS] }),
                path: '/statistics/prescriptions',
                component: lazy(() => import('./views/statistics/pages/PrescriptionsStats'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.CONSULT_DETAILED_STATS] }),
                path: '/statistics/insurances',
                component: lazy(() => import('./views/statistics/pages/AssurancesStats'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.CONSULT_DETAILED_STATS] }),
                path: '/statistics/antibiogrammes-fongigrammes',
                component: lazy(() => import('./views/statistics/pages/AssurancesStats'))
            },
            {
                exact: true,
                guard: AuthGuard,
                path: '/users/:uuid/profile',
                component: lazy(() => import('./views/profile'))
            },
            /**---------------------USERS----------------------------- */
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.ADD_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.EDIT_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.DELETE_PATIENT, UsersRolesAttributes.BILL_AND_PRESCRIPTION_MANAGER, UsersRolesAttributes.DELETE_BILL, UsersRolesAttributes.EDIT_USER_PASSWORD] }),
                path: '/users/patients',
                component: lazy(() => import('./views/patients/pages/UsersList'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.BILL_AND_PRESCRIPTION_MANAGER] }),
                path: '/users/patients/:uuid',
                component: lazy(() => import('./views/patients/pages/UsersFolder'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.BILL_AND_PRESCRIPTION_MANAGER, UsersRolesAttributes.DELETE_BILL, UsersRolesAttributes.DELETE_PRESCRIPTION] }),
                path: '/users/patients/:uuid/new-prescription',
                component: lazy(() => import('./views/patients/pages/UsersPrescriptionAndBillsPage'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.BILL_AND_PRESCRIPTION_MANAGER] }),
                path: '/users/patients/:uuid/:prescriptionuuid',
                component: lazy(() => import('./views/patients/pages/UsersPrescriptionAndBillsPage'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.ADD_STAFF_MEMBER, UsersRolesAttributes.EDIT_STAFF_MEMBER, UsersRolesAttributes.DELETE_STAFF_MEMBER] }),
                path: '/users/staff',
                component: lazy(() => import('./views/patients/pages/UsersList'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.ADD_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.EDIT_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.DELETE_PRESCRIBER] }),
                path: '/users/prescribers',
                component: lazy(() => import('./views/patients/pages/UsersList'))
            },

            /**----------------- TECHNIQUE -------------------- */
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.SAMPLE_PATIENTS] }),
                path: '/technique/samplings',
                component: lazy(() => import('./views/technique/sampling/pages/AutomaticSampling'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.CONSULT_BENCH_BOOK] }),
                path: '/technique/bench-book',
                component: lazy(() => import('./views/technique/benchBook/pages/BenchBook'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.SAMPLE_PATIENTS] }),
                path: '/technique/samplings/manual',
                component: lazy(() => import('./views/technique/sampling/pages/ManualSampling'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.TREAT_SAMPLES] }),
                path: '/technique/units',
                component: lazy(() => import('./views/technique/units/pages/UnitsList'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.TREAT_SAMPLES] }),
                path: '/technique/units/treatment/:uuid',
                component: lazy(() => import('./views/technique/units/pages/Treatment'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.VALIDATE_SAMPLES] }),
                path: '/technique/validation',
                component: lazy(() => import('./views/technique/units/pages/ValidationsList'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.VALIDATE_SAMPLES] }),
                path: '/technique/units/validation/:uuid',
                component: lazy(() => import('./views/technique/units/pages/Treatment'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.EDIT_VALIDATED_PRESCRIPTIONS, UsersRolesAttributes.TREAT_SAMPLES] }),
                path: '/technique/units/edit-sample/:uuid',
                component: lazy(() => import('./views/technique/units/pages/Treatment'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.PRINT_AND_WITHDRAW_RESULTS] }),
                path: '/technique/print-and-withdraw',
                component: lazy(() => import('./views/technique/print-and-withdraw/pages/PrintAndWithdraw'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.CONSULT_GENERAL_PRESCRIPTIONS_HISTORIES] }),
                path: '/technique/general-histories',
                component: lazy(() => import('./views/technique/units/pages/GeneralPrescriptionHistories'))
            },


            /**----------------- CAISSE -------------------- */
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.CONSULT_AND_PRINT_CAISSE_STATE] }),
                path: '/cash/general',
                component: lazy(() => import('./views/caisse/pages/CaisseGenerale'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.PERSONAL_CAISSE] }),
                path: '/cash/personnal',
                component: lazy(() => import('./views/caisse/pages/CaisseGenerale'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.EDIT_CAISSE_OUTPUT, UsersRolesAttributes.VALIDATE_CAISSE_OUTPUT] }),
                path: '/cash/cash-outputs',
                component: lazy(() => import('./views/caisse/pages/CaisseOutputs'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.VALIDATE_CAISSE_OUTPUT] }),
                path: '/cash/cash-outputs/validation',
                component: lazy(() => import('./views/caisse/pages/CaisseOutputsValidation'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.CONSULT_AND_PRINT_QUOTE_PART, UsersRolesAttributes.ADD_QUOTE_PART, UsersRolesAttributes.DELETE_QUOTE_PART] }),
                path: '/cash/quote-part',
                component: lazy(() => import('./views/caisse/pages/QuoteParts'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.CONSULT_AND_PRINT_QUOTE_PART, UsersRolesAttributes.ADD_QUOTE_PART, UsersRolesAttributes.DELETE_QUOTE_PART] }),
                path: '/cash/quote-part-assurances',
                component: lazy(() => import('./views/caisse/pages/QuotePartsAssurances'))
            },
            {
                exact: true,
                // guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [] }),
                guard: AuthGuard,
                path: '/prescriber/quote-part/details',
                component: lazy(() => import('./views/caisse/pages/QuotePartsDetailsForPrescriber'))
            },
            {
                exact: true,
                // guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [] }),
                guard: AuthGuard,
                path: '/prescriber/quote-part',
                component: lazy(() => import('./views/caisse/pages/QuotePartsForPrescriber'))
            },
            {
                exact: true,
                // guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [] }),
                guard: AuthGuard,
                path: '/prescriber/prescriptions',
                component: lazy(() => import('./views/caisse/pages/PrescriptionsForPrescriber'))
            },

            /**-----------------ADMINISTRATION -------------------- */
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.CONSULT_EXAMS_LIST, UsersRolesAttributes.ADD_OR_EDIT_EXAMS, UsersRolesAttributes.EDIT_EXAMS_SETTINGS, UsersRolesAttributes.DELETE_EXAMS] }),
                path: '/admin/exams/list',
                component: lazy(() => import('./views/admin/exams/pages/ExamsList'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.EDIT_EXAMS_SETTINGS] }),
                path: '/admin/exams/exam/:uuid',
                component: lazy(() => import('./views/admin/exams/pages/ExamsSettings'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.ADD_OR_EDIT_EXAMS_TUBES, UsersRolesAttributes.DELETE_EXAMS_TUBES] }),
                path: '/admin/exams/tubes',
                component: lazy(() => import('./views/admin/exams/pages/ExamsTubesList'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.ADD_OR_EDIT_EXAMS_TYPES, UsersRolesAttributes.DELETE_EXAMS_TYPES, UsersRolesAttributes.EDIT_EXAMS_TYPES_SETTINGS, UsersRolesAttributes.EDIT_QUOTE_PART_PERCENTAGE] }),
                path: '/admin/exams/types',
                component: lazy(() => import('./views/admin/exams/pages/ExamsTypesList'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.EDIT_EXAMS_TYPES_SETTINGS] }),
                path: '/admin/exams/types/:uuid',
                component: lazy(() => import('./views/admin/exams/pages/ExamsTypesSettings'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.ADD_OR_EDIT_RESULTS_BANK, UsersRolesAttributes.DELETE_RESULTS_BANK] }),
                path: '/admin/exams/parameters-results',
                component: lazy(() => import('./views/admin/exams/pages/ExamsParametersValues'))
            },

            {
                exact: true,
                path: '/admin/profiles',
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.EDIT_USER_PROFILE] }),
                component: lazy(() => import('./views/admin/profiles/pages/profilesList/Profiles'))
            },

            {
                exact: true,
                path: '/admin/pending-discounts',
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.VALIDATE_PENDING_DISCOUNTS] }),
                component: lazy(() => import('./views/admin/PendingDiscounts/PendingDiscounts'))
            },

            {
                exact: true,
                path: '/admin/push-notifications',
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.SEND_PUSH_NOTIFICATIONS] }),
                component: lazy(() => import('./views/admin/PushNotifications/PushNotifications'))
            },

            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.ADD_OR_EDIT_LABS, UsersRolesAttributes.DELETE_LABS] }),
                path: '/admin/configs/laboratories',
                component: lazy(() => import('./views/admin/configs/laboratories/pages/LaboratoriesList'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.ADD_OR_EDIT_PARTNERS, UsersRolesAttributes.DELETE_PARTNERS] }),
                path: '/admin/configs/partners',
                component: lazy(() => import('./views/admin/configs/partners/pages/PartnersList'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.ADD_CAISSE_OUTPUT_ORDONNATOR, UsersRolesAttributes.EDIT_CAISSE_OUTPUT_ORDONNATOR, UsersRolesAttributes.DELETE_CAISSE_OUTPUT_ORDONNATOR, UsersRolesAttributes.ADD_CAISSE_OUTPUT_DOMAIN, UsersRolesAttributes.EDIT_CAISSE_OUTPUT_DOMAIN, UsersRolesAttributes.DELETE_CAISSE_OUTPUT_DOMAIN] }),
                path: '/admin/configs/caisse',
                component: lazy(() => import('./views/admin/configs/caisse/pages/CaisseConfigs'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.CONSULT_HISTORIES] }),
                path: '/admin/histories',
                component: lazy(() => import('./views/admin/histories/Histories'))
            },
            {
                exact: true,
                guard: (children) => AuthorizedGuard({ ...children, authorizedRoles: [UsersRolesAttributes.HANDLE_TAXES_MANAGEMENT] }),
                path: '/admin/taxes',
                component: lazy(() => import('./views/admin/taxes/TaxesManagement'))
            },



            {
                path: '*',
                exact: true,
                component: () => <Redirect to={BASE_URL} />
            }
        ]
    }
];

export default routes;

