import React, { useState } from 'react';
import { Media, FormControl, Button, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { API_SERVER, notificationConfigs } from '../../../../../../../config/constant';
import axios from 'axios';
import { Store } from 'react-notifications-component';

// import Messages from './Messages';

const PrescriptionDetails = ({ tempPrescription, chatOpen, listOpen, closed }) => {
    const [isLoading, setIsLoading] = useState(false);
    let chatClass = ['header-chat'];
    if (chatOpen && listOpen) {
        chatClass = [...chatClass, 'open'];
    }


    const updateTempPrescription = async () => {
        setIsLoading(true)
        await axios.patch(API_SERVER + 'technique/temp-prescription/update', {
            tempPrescriptionId: tempPrescription.uuid,
        }).then((res) => {
            setIsLoading(false)
            Store.addNotification({
                ...notificationConfigs, message: "Opération effectuée avec succès"
            })
        }).catch((err) => {
            setIsLoading(false)
            console.log({ err })
            Store.addNotification({
                ...notificationConfigs, title: "Ooups!🙁", message: "Erreur, SVP Réessayer plus tard", type: "danger"
            })
        })
    }


    return (
        <React.Fragment>
            <div className={chatClass.join(' ')}>
                <div className="h-list-header">
                    <h6>Détails de la prescription</h6>
                    <Link to="#" className="h-back-user-list" onClick={closed}>
                        <i className="feather icon-chevron-left text-muted" />
                    </Link>
                </div>
                <div className="h-list-body">
                    <div className="text-center p-t-30">
                        <PerfectScrollbar>
                            <div className="">
                                <h5>Patient: <b>{tempPrescription.patient}</b></h5>
                                <br />
                                <h5>Prescripteur: <b>{tempPrescription.prescriberName}</b></h5>
                                <br />
                                <hr />
                                <div >
                                    <h6>Examens prescrits</h6>
                                    <h3>{tempPrescription.selectedExams}</h3>
                                    <h3>{tempPrescription.selectedImagingExams}</h3>
                                </div>
                            </div>
                            <div className='m-t-50'>
                                <OverlayTrigger overlay={<Tooltip> <i className="feather icon-check-circle" /> {'  PATIENT Reçu'}</Tooltip>} >
                                    <Button className="btn p-l-20 p-30 theme-bg" variant={'success'} disabled={isLoading} onClick={() => updateTempPrescription()}>
                                        PATIENT Reçu
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>

                {/* <div className="h-list-footer">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <Button variant="success" className="btn-attach">
                                <i className="feather icon-paperclip" />
                            </Button>
                        </InputGroup.Prepend>
                        <FormControl type="text" name="h-chat-text" className="h-send-chat" placeholder="Write hear . . " />
                        <Button type="submit" className="input-group-append btn-send">
                            <i className="feather icon-message-circle" />
                        </Button>
                    </InputGroup>
                </div> */}
            </div>
        </React.Fragment>
    );
};

export default PrescriptionDetails;
