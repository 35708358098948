import { useSelector } from 'react-redux';

let account;
const Constants = () => {
    account = useSelector((state) => state.account);

    return <div></div>;
};

export default Constants;

export const BASENAME = ''; // don't add '/' at end off BASENAME
export const BASE_URL = '/dashboard';
export const BASE_TITLE = ' | LABORATOIRE BETHANIE ';
// export const API_SERVER = 'http://127.0.0.1:5000/';
// export const API_SERVER = 'http://192.168.68.114:5000/';
// export const API_SERVER = 'http://192.168.100.250:5000/';
export const API_SERVER = 'https://api.labobethanie.com/';

export const MAXIMUM_DISCOUNT_ALLOWED = 10;
export const CANCELLING_MOTIVE_FOR_DISCOUNT_REJECTION = "Réduction non accordée, dossier annulé et patient remboursé";

export const CONFIG = {
    layout: 'vertical', // disable on free version
    subLayout: '', // disable on free version
    collapseMenu: false, // mini-menu
    layoutType: 'menu-dark', // disable on free version
    navIconColor: false, // disable on free version
    headerBackColor: 'header-default', // disable on free version
    navBackColor: 'navbar-default', // disable on free version
    navBrandColor: 'brand-default', // disable on free version
    navBackImage: true, // disable on free version
    rtlLayout: false, // disable on free version
    navFixedLayout: true, // disable on free version
    headerFixedLayout: false, // disable on free version
    boxLayout: false, // disable on free version
    navDropdownIcon: 'style1', // disable on free version
    navListIcon: 'style1', // disable on free version
    navActiveListColor: 'active-default', // disable on free version
    navListTitleColor: 'title-default', // disable on free version
    navListTitleHide: false, // disable on free version
    configBlock: true, // disable on free version
    layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // disable on free version
    layout6BackSize: '' // disable on free version
};

export const ACTIVE_ACCOUNT_STATUS = 'ACTIVE';
export const ARCHIVED_ACCOUNT_STATUS = 'ARCHIVED';
export const STAFF_USER_TYPE = 'STAFF';
export const PRESCRIBER_USER_TYPE = 'PRESCRIBER';
export const PATIENT_USER_TYPE = 'PATIENT';

export const TUBES_COLORS = [
    { color: 'Incolore', code: '#FFFFFF00' },
    { color: 'Blanc', code: '#FFFFFF' },
    { color: 'Bleu', code: '#46c7f2' },
    { color: 'Rouge', code: '#FF0000' },
    { color: 'Vert', code: '#00FF00' },
    { color: 'Violet', code: '#EE82EE' },
    { color: 'Jaune', code: '#FFFF00' },
    { color: 'Or', code: '#FFD700' },
    { color: 'Noir', code: '#000000' },
    { color: 'Gris', code: '#b2c4ca' }
];

export const notificationConfigs = {
    title: 'Yes!! 😎',
    message: 'Succès',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
        duration: 5000,
        onScreen: true
    }
};

export const BILL_PAID = 'PAID';
export const BILL_UN_PAID = 'UN_PAID';

export const CaisseOutputStatuses = {
    PENDING: 'PENDING',
    RESOLVED: 'RESOLVED',
    CANCELLED: 'CANCELLED'
};

export const PriseEnCharge = {
    PERSONNELLE: 'PERSONNELLE',
    ASSURANCE: 'ASSURANCE',
    SOUS_TRAITANT: 'SOUS-TRAITANT'
};

export const SampleProvenance = {
    INTERNE: 'INTERNE',
    EXTERNE: 'EXTERNE'
};

export const ClinicalInfo = {
    BILAN_DE_SANTE: 'BILAN DE SANTÉ',
    BILAN_PRENATAL: 'BILAN PRÉNATAL',
    BILAN_PRENUPTIAL: 'BILAN PRÉNUPTIAL',
    BILAN_PREOPERATOIRE: 'BILAN PRÉOPÉRATOIRE',
    BILAN_DINFERTILITE: "BILAN D'INFERTILITÉ",
    AUTRE: 'AUTRE'
};

export const PrescriptionStatuses = {
    // ---------- Prescription State enums
    PENDING: 'EN ATTENTE',
    PENDING_DISCOUNT_VALIDATION: 'RÉDUCTION EN ATTENTE DE VALIDATION',
    SAMPLING: 'EN PRÉLÈVEMENT', //When billed, this is the case
    PAILLASSE: 'EN PAILLASSE',
    VALIDATION: 'EN VALIDATION',
    IMPRESSION: 'EN IMPRESSION',
    CANCELLED: 'ANNULÉ(E)',
    WITHDRAWN: 'RETIRÉ'
};

export const UsersRolesAttributes = {
    // ---------- GESTION DES PATIENTS
    ADD_PATIENT_OR_PRESCRIPBER: 'add_patient_or_prescriber',
    EDIT_PATIENT_OR_PRESCRIPBER: 'edit_patient_or_prescriber',
    DELETE_PATIENT: 'delete_patient',
    DELETE_PRESCRIBER: 'delete_prescriber',
    ADD_STAFF_MEMBER: 'add_staff_member',
    EDIT_STAFF_MEMBER: 'edit_staff_member',
    DELETE_STAFF_MEMBER: 'delete_staff_member',
    BILL_AND_PRESCRIPTION_MANAGER: 'bill_and_prescription_manager',
    DELETE_BILL: 'delete_bill',
    DELETE_PRESCRIPTION: 'delete_prescription',
    SOUS_TRAITER_PRESCRIPTION: 'sous_traiter_prescription',
    MODIFY_PRESCRIPTION: 'modify_prescription',
    CANCEL_PRESCRIPTION: 'cancel_prescription',
    SAVE_BILL_LESS_THAN_80: 'save_bill_less_than_80',

    // ---------- CAISSE
    PERSONAL_CAISSE: 'personnal_caisse',
    CONSULT_AND_PRINT_CAISSE_STATE: 'consult_and_print_caisse',
    EDIT_CAISSE_OUTPUT: 'edit_caisse_output',
    VALIDATE_CAISSE_OUTPUT: 'validate_caisse_output',
    DELETE_CAISSE_OUTPUT: 'delete_caisse_output', //Just invalidated ones
    CONSULT_AND_PRINT_QUOTE_PART: 'consult_and_print_quote_part',
    ADD_QUOTE_PART: 'add_quote_part',
    DELETE_QUOTE_PART: 'delete_quote_part',

    // ---------- STATISTICS
    CONSULT_STATS: 'consult_stats',
    CONSULT_ASSURANCES_STATS: 'consult_assurances_stats',
    CONSULT_DETAILED_STATS: 'consult_detailed_stats',

    // ---------- TECHNIQUE
    CONSULT_BENCH_BOOK: 'consult_bench_book',
    SAMPLE_PATIENTS: 'sample_patients',
    TREAT_SAMPLES: 'treat_samples', //Techniquer en paillasse
    VALIDATE_SAMPLES: 'validate_samples',
    PRINT_AND_WITHDRAW_RESULTS: 'print_and_withdraw_results',
    CONSULT_GENERAL_PRESCRIPTIONS_HISTORIES: 'consult_general_prescriptions_histories',
    EDIT_VALIDATED_PRESCRIPTIONS: 'edit_validated_prescriptions',

    // ---------- ADMINSITRATION

    CONSULT_EXAMS_LIST: 'consult_exams_list',
    ADD_OR_EDIT_EXAMS: 'add_or_edit_exams',
    EDIT_EXAMS_SETTINGS: 'edit_exams_settings',
    EDIT_EXAMS_PRICE: 'edit_exams_price',
    EDIT_EXAMS_PRICE_ALL_LABS: 'edit_exams_price_all_labs',
    DELETE_EXAMS: 'delete_exams',
    ADD_OR_EDIT_EXAMS_TUBES: 'add_or_edit_exams_tubes',
    DELETE_EXAMS_TUBES: 'delete_exams_tubes',
    ADD_OR_EDIT_EXAMS_TYPES: 'add_or_edit_exams_types',
    DELETE_EXAMS_TYPES: 'delete_exams_types',
    EDIT_EXAMS_TYPES_SETTINGS: 'edit_exams_types_settings',
    EDIT_QUOTE_PART_PERCENTAGE: 'edit_quote_part_percentage',
    ADD_OR_EDIT_RESULTS_BANK: 'add_or_edit_results_bank',
    DELETE_RESULTS_BANK: 'delete_results_bank',
    ADD_OR_EDIT_LABS: 'add_or_edit_labs',
    DELETE_LABS: 'delete_labs',
    ADD_OR_EDIT_PARTNERS: 'add_or_edit_partners',
    DELETE_PARTNERS: 'delete_partners',
    ADD_CAISSE_OUTPUT_ORDONNATOR: 'add_caisse_output_ordonnator',
    EDIT_CAISSE_OUTPUT_ORDONNATOR: 'edit_caisse_output_ordonnator',
    DELETE_CAISSE_OUTPUT_ORDONNATOR: 'delete_caisse_output_ordonnator',
    ADD_CAISSE_OUTPUT_DOMAIN: 'add_caisse_output_domain',
    EDIT_CAISSE_OUTPUT_DOMAIN: 'edit_caisse_output_domain',
    DELETE_CAISSE_OUTPUT_DOMAIN: 'delete_caisse_output_domain',
    CONSULT_HISTORIES: 'consult_histories',
    EDIT_USER_PROFILE: 'edit_user_profile',
    VALIDATE_PENDING_DISCOUNTS: 'validate_pending_discounts',
    HANDLE_TAXES_MANAGEMENT: 'handle_taxes_management',
    CHOOSE_USER_PROFILE: 'choose_user_profile',
    SEND_PUSH_NOTIFICATIONS: 'send_push_notifications',
    EDIT_USER_PASSWORD: 'edit_user_password',
    EDIT_USER_SIGNATURE: 'edit_user_signature',
    SWITCH_LAB: 'switch_lab',
    EXPORT_PATIENTS_LIST: 'export_patients_list'
};

export const DayWeekOrMonth = {
    DAY: 'DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH'
};

export const SpecialCharacters = {
    µ: 'micro (µ)',
    α: 'alpha (α)',
    '≤': 'Inférieur ou égal',
    '≥': 'Supérieur ou égal',
    β: 'Bêta (β)',
    '⁰': 'exp0',
    '¹': 'exp1',
    '²': 'exp2',
    '³': 'exp3',
    '⁴': 'exp4',
    '⁵': 'exp5',
    '⁶': 'exp6',
    '⁷': 'exp7',
    '⁸': 'exp8',
    '⁹': 'exp9',
    '⁻': 'exp-',
    '⁺': 'exp+',
    '⁾': 'exp)',
    '⁽': 'exp(',
    '°': 'exp°',
    '‰': 'Pour 1000',
    Ɣ: 'Gamma (Ɣ)'
};

export const TechniquesFunctionNames = {
    correctedCalcium: 'Calcium corrigé',
    ctf: 'Capacité totale de Fixation',
    bilirubineIndirecte: 'Bilirubine Indirecte',
    css: 'Coefficient de saturation en fer',
    clairanceMDRD: 'Clairance de la créatinine (MDRD)',
    ratioUreeUrinaireUreeSanguine: 'Rapport Urée urinaire / Urée sanguine', //** Calcul incluant plusieurs examens
    LDLCholesterol: 'LDL Cholesterol',
    indiceAtherogenicite: "Indice d'athérogénicité",
    ratioAlatAsat: 'Rapport ALAT/ASAT',
    resultat24H: 'Résultat sur 24H',
    osmolarity: 'Osmolarité Urinaire', //** Calcul incluant plusieurs examens
    ratioAlbuCrea: 'Rapport Albuminurie Créatinurie', //** Calcul incluant plusieurs examens
    clairanceCreatinine: 'Clairance Créatinine',
    ratioPSAlPSAt: 'Rapport PSAl / PSAt'
};

//This is the list of exams where there are parameters whose value are calculted from the ones of the Leucocytes
export const examsWithParamsLikedToLeucocytes = [
    'NFS',
    'ECB LP+ATB',
    'ECB LA+ATB',
    'ECP LPERI+ATB',
    'ECB LPerica+ATB',
    'ECB LS+ATB',
    'ECB LArti+ATB',
    'ECB LCR+ATB'
];
