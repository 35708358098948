import { UsersRolesAttributes } from "./config/constant";
import { containsElements } from "./utils/otherUtilities";


/**
 * Returns the menu items according to the provided User Roles
 * @param {*} userRoles 
 * @returns 
 */
export const getMenuItems = (userRoles) => {
    const menuItems = [
        {
            id: 'navigation',
            title: 'Accueil',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Tableau de Bord',
                    type: 'item',
                    url: '/',
                    icon: 'feather icon-home purple'
                },
                containsElements(userRoles, [UsersRolesAttributes.CONSULT_STATS, UsersRolesAttributes.CONSULT_DETAILED_STATS]) && {
                    id: 'stats',
                    title: 'Statistiques',
                    type: 'collapse',
                    icon: 'fa fa-database primary',
                    children: [
                        containsElements(userRoles, [UsersRolesAttributes.CONSULT_STATS, UsersRolesAttributes.CONSULT_DETAILED_STATS]) && {
                            id: 'examsList',
                            title: 'Générales',
                            type: 'item',
                            url: '/statistics',
                            // icon: 'feather icon-wheel-chair'
                        },
                        containsElements(userRoles, [UsersRolesAttributes.CONSULT_DETAILED_STATS]) && {
                            id: 'examsTubes',
                            title: "Assurances",
                            type: 'item',
                            url: '/statistics/insurances',
                            // icon: 'feather icon-user success'
                        },
                        containsElements(userRoles, [UsersRolesAttributes.CONSULT_DETAILED_STATS]) && {
                            id: 'examsTypes',
                            title: "Sous-traitances",
                            type: 'item',
                            url: '/statistics/sous-traitance',
                            // icon: 'feather icon-user success'
                        },
                        containsElements(userRoles, [UsersRolesAttributes.CONSULT_DETAILED_STATS]) && {
                            id: 'antiBioFongi',
                            title: "Antibiogrammes et Antifongigrammes",
                            type: 'item',
                            url: '/statistics/antibiogrammes-fongigrammes',
                            // icon: 'feather icon-user success'
                        },
                    ]
                }
            ]
        },
        containsElements(userRoles, [UsersRolesAttributes.ADD_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.EDIT_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.DELETE_PATIENT, UsersRolesAttributes.BILL_AND_PRESCRIPTION_MANAGER, UsersRolesAttributes.DELETE_BILL, UsersRolesAttributes.EDIT_USER_PASSWORD, UsersRolesAttributes.ADD_STAFF_MEMBER, UsersRolesAttributes.EDIT_STAFF_MEMBER, UsersRolesAttributes.DELETE_STAFF_MEMBER, UsersRolesAttributes.ADD_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.EDIT_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.DELETE_PRESCRIBER]) && {
            id: 'patients',
            title: 'Patients',
            type: 'group',
            icon: 'icon-group',
            children: [
                containsElements(userRoles, [UsersRolesAttributes.ADD_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.EDIT_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.DELETE_PATIENT, UsersRolesAttributes.BILL_AND_PRESCRIPTION_MANAGER, UsersRolesAttributes.DELETE_BILL, UsersRolesAttributes.EDIT_USER_PASSWORD]) && {
                    id: 'patients',
                    title: 'Patients',
                    type: 'item',
                    url: '/users/patients',
                    icon: 'feather icon-users success'
                }
            ]
        },
        containsElements(userRoles, [UsersRolesAttributes.ADD_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.EDIT_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.DELETE_PATIENT, UsersRolesAttributes.BILL_AND_PRESCRIPTION_MANAGER, UsersRolesAttributes.DELETE_BILL, UsersRolesAttributes.EDIT_USER_PASSWORD, UsersRolesAttributes.ADD_STAFF_MEMBER, UsersRolesAttributes.EDIT_STAFF_MEMBER, UsersRolesAttributes.DELETE_STAFF_MEMBER, UsersRolesAttributes.ADD_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.EDIT_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.DELETE_PRESCRIBER]) && {
            id: 'users',
            title: 'Utilisateurs',
            type: 'group',
            icon: 'icon-group',
            children: [
                containsElements(userRoles, [UsersRolesAttributes.ADD_STAFF_MEMBER, UsersRolesAttributes.EDIT_STAFF_MEMBER, UsersRolesAttributes.DELETE_STAFF_MEMBER]) && {
                    id: 'staff',
                    title: 'Personnel',
                    type: 'item',
                    url: '/users/staff',
                    icon: 'feather icon-user warning'
                },
                containsElements(userRoles, [UsersRolesAttributes.ADD_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.EDIT_PATIENT_OR_PRESCRIPBER, UsersRolesAttributes.DELETE_PRESCRIBER]) && {
                    id: 'prescribers',
                    title: 'Prescipteurs',
                    type: 'item',
                    url: '/users/prescribers',
                    icon: 'feather icon-user info'
                }
            ]
        },
        containsElements(userRoles, [UsersRolesAttributes.PERSONAL_CAISSE, UsersRolesAttributes.CONSULT_AND_PRINT_CAISSE_STATE, UsersRolesAttributes.EDIT_CAISSE_OUTPUT, UsersRolesAttributes.VALIDATE_CAISSE_OUTPUT, UsersRolesAttributes.CONSULT_AND_PRINT_QUOTE_PART, UsersRolesAttributes.ADD_QUOTE_PART, UsersRolesAttributes.DELETE_QUOTE_PART]) && {
            id: 'caisse',
            title: 'Caisse',
            type: 'group',
            icon: 'icon-group',
            children: [
                containsElements(userRoles, [UsersRolesAttributes.PERSONAL_CAISSE]) && {
                    id: 'personnalCash',
                    title: 'Caisse Personnelle',
                    type: 'item',
                    url: '/cash/personnal',
                    icon: 'far fa-money-bill-alt warning'
                    // icon: 'feather icon-wheel-chair'
                },
                containsElements(userRoles, [UsersRolesAttributes.CONSULT_AND_PRINT_CAISSE_STATE]) && {
                    id: 'generalCash',
                    title: 'Caisse Générale',
                    type: 'item',
                    url: '/cash/general',
                    icon: 'fas fa-money-bill-wave success'
                },
                containsElements(userRoles, [UsersRolesAttributes.EDIT_CAISSE_OUTPUT, UsersRolesAttributes.VALIDATE_CAISSE_OUTPUT]) && {
                    id: 'bootstrap',
                    title: 'Sorties de Caisse',
                    type: 'item',
                    url: '/cash/cash-outputs',
                    icon: 'fas fa-money-bill-wave primary'
                },
                containsElements(userRoles, [UsersRolesAttributes.CONSULT_AND_PRINT_QUOTE_PART, UsersRolesAttributes.ADD_QUOTE_PART, UsersRolesAttributes.DELETE_QUOTE_PART]) && {
                    id: 'doctorsCotePart',
                    title: 'Quotes-parts',
                    type: 'item',
                    url: '/cash/quote-part',
                    icon: 'fas fa-money-check-alt danger'
                }
            ]
        },
        containsElements(userRoles, [UsersRolesAttributes.CONSULT_BENCH_BOOK, UsersRolesAttributes.SAMPLE_PATIENTS, UsersRolesAttributes.TREAT_SAMPLES, UsersRolesAttributes.VALIDATE_SAMPLES, UsersRolesAttributes.PRINT_AND_WITHDRAW_RESULTS]) && {
            id: 'technique',
            title: 'Technique',
            type: 'group',
            icon: 'icon-group',
            children: [
                containsElements(userRoles, [UsersRolesAttributes.SAMPLE_PATIENTS]) && {
                    id: 'sampling',
                    title: 'Prélèvement',
                    type: 'item',
                    url: '/technique/samplings',
                    icon: 'feather icon-user warning'
                },
                containsElements(userRoles, [UsersRolesAttributes.CONSULT_BENCH_BOOK]) && {
                    id: 'benchBook',
                    title: 'Cahier de Paillasse',
                    type: 'item',
                    url: '/technique/bench-book',
                    icon: 'feather icon-book purple'
                },
                containsElements(userRoles, [UsersRolesAttributes.TREAT_SAMPLES]) && {
                    id: 'units',
                    title: 'Unités',
                    type: 'item',
                    url: '/technique/units',
                    icon: 'feather icon-calendar info'
                },
                containsElements(userRoles, [UsersRolesAttributes.VALIDATE_SAMPLES]) && {
                    id: 'resultsValidating',
                    title: 'Validation de Résultats',
                    type: 'item',
                    url: '/technique/validation',
                    icon: 'feather icon-check-circle success'
                },
                containsElements(userRoles, [UsersRolesAttributes.PRINT_AND_WITHDRAW_RESULTS]) && {
                    id: 'resultPrint',
                    title: 'Impression et Retrait',
                    type: 'item',
                    url: '/technique/print-and-withdraw',
                    icon: 'feather icon-printer danger'
                },
                containsElements(userRoles, [UsersRolesAttributes.CONSULT_GENERAL_PRESCRIPTIONS_HISTORIES]) && {
                    id: 'generalHistories',
                    title: 'État des Prescriptions',
                    type: 'item',
                    url: '/technique/general-histories',
                    icon: 'fas fa-history warning'
                }
            ]
        },
        containsElements(userRoles, [UsersRolesAttributes.ADD_OR_EDIT_EXAMS, UsersRolesAttributes.EDIT_EXAMS_SETTINGS, UsersRolesAttributes.DELETE_EXAMS, UsersRolesAttributes.ADD_OR_EDIT_EXAMS_TUBES, UsersRolesAttributes.DELETE_EXAMS_TUBES, UsersRolesAttributes.ADD_OR_EDIT_EXAMS_TYPES, UsersRolesAttributes.DELETE_EXAMS_TYPES, UsersRolesAttributes.EDIT_EXAMS_TYPES_SETTINGS, UsersRolesAttributes.EDIT_QUOTE_PART_PERCENTAGE, UsersRolesAttributes.ADD_OR_EDIT_RESULTS_BANK, UsersRolesAttributes.DELETE_RESULTS_BANK, UsersRolesAttributes.ADD_OR_EDIT_LABS, UsersRolesAttributes.DELETE_LABS, UsersRolesAttributes.ADD_OR_EDIT_PARTNERS, UsersRolesAttributes.DELETE_PARTNERS, UsersRolesAttributes.ADD_CAISSE_OUTPUT_ORDONNATOR, UsersRolesAttributes.EDIT_CAISSE_OUTPUT_ORDONNATOR, UsersRolesAttributes.DELETE_CAISSE_OUTPUT_ORDONNATOR, UsersRolesAttributes.ADD_CAISSE_OUTPUT_DOMAIN, UsersRolesAttributes.EDIT_CAISSE_OUTPUT_DOMAIN, UsersRolesAttributes.DELETE_CAISSE_OUTPUT_DOMAIN, UsersRolesAttributes.CONSULT_HISTORIES, UsersRolesAttributes.EDIT_USER_PROFILE]) && {
            id: 'administration',
            title: 'Administration',
            type: 'group',
            icon: 'icon-group',
            children: [
                containsElements(userRoles, [UsersRolesAttributes.VALIDATE_PENDING_DISCOUNTS]) && {
                    id: 'forms',
                    title: 'Réductions en Attente',
                    type: 'item',
                    url: '/admin/pending-discounts',
                    icon: 'feather icon-clock warning'
                },
                containsElements(userRoles, [UsersRolesAttributes.CONSULT_EXAMS_LIST, UsersRolesAttributes.ADD_OR_EDIT_EXAMS, UsersRolesAttributes.EDIT_EXAMS_SETTINGS, UsersRolesAttributes.DELETE_EXAMS, UsersRolesAttributes.ADD_OR_EDIT_EXAMS_TUBES, UsersRolesAttributes.DELETE_EXAMS_TUBES, UsersRolesAttributes.ADD_OR_EDIT_EXAMS_TYPES, UsersRolesAttributes.DELETE_EXAMS_TYPES, UsersRolesAttributes.EDIT_EXAMS_TYPES_SETTINGS, UsersRolesAttributes.EDIT_QUOTE_PART_PERCENTAGE, UsersRolesAttributes.ADD_OR_EDIT_RESULTS_BANK, UsersRolesAttributes.DELETE_RESULTS_BANK]) && {
                    id: 'exams',
                    title: 'Examens',
                    type: 'collapse',
                    // icon: 'icon-group',
                    icon: 'feather icon-file primary',
                    children: [
                        containsElements(userRoles, [UsersRolesAttributes.CONSULT_EXAMS_LIST, UsersRolesAttributes.ADD_OR_EDIT_EXAMS, UsersRolesAttributes.EDIT_EXAMS_SETTINGS, UsersRolesAttributes.DELETE_EXAMS]) && {
                            id: 'examsList',
                            title: 'Liste des examens',
                            type: 'item',
                            url: '/admin/exams/list',
                            // icon: 'feather icon-wheel-chair'
                        },
                        // {
                        //     id: 'examsGroup',
                        //     title: "Groupes d'examens",
                        //     type: 'item',
                        //     url: '/admin/exams/groups',
                        //     // icon: 'feather icon-user warning'
                        // },
                        containsElements(userRoles, [UsersRolesAttributes.ADD_OR_EDIT_EXAMS_TUBES, UsersRolesAttributes.DELETE_EXAMS_TUBES]) && {
                            id: 'examsTubes',
                            title: "Tubes et Contenants",
                            type: 'item',
                            url: '/admin/exams/tubes',
                            // icon: 'feather icon-user success'
                        },
                        containsElements(userRoles, [UsersRolesAttributes.ADD_OR_EDIT_EXAMS_TYPES, UsersRolesAttributes.DELETE_EXAMS_TYPES, UsersRolesAttributes.EDIT_EXAMS_TYPES_SETTINGS, UsersRolesAttributes.EDIT_QUOTE_PART_PERCENTAGE]) && {
                            id: 'examsTypes',
                            title: "Types d'examens",
                            type: 'item',
                            url: '/admin/exams/types',
                            // icon: 'feather icon-user success'
                        },
                        containsElements(userRoles, [UsersRolesAttributes.ADD_OR_EDIT_RESULTS_BANK, UsersRolesAttributes.DELETE_RESULTS_BANK]) && {
                            id: 'benchesTechniques',
                            title: "Banques des Résultats",
                            type: 'item',
                            url: '/admin/exams/parameters-results',
                            // icon: 'feather icon-user success'
                        }
                    ]
                },
                containsElements(userRoles, [UsersRolesAttributes.ADD_OR_EDIT_LABS, UsersRolesAttributes.DELETE_LABS, UsersRolesAttributes.ADD_OR_EDIT_PARTNERS, UsersRolesAttributes.DELETE_PARTNERS, UsersRolesAttributes.ADD_CAISSE_OUTPUT_ORDONNATOR, UsersRolesAttributes.EDIT_CAISSE_OUTPUT_ORDONNATOR, UsersRolesAttributes.DELETE_CAISSE_OUTPUT_ORDONNATOR, UsersRolesAttributes.ADD_CAISSE_OUTPUT_DOMAIN, UsersRolesAttributes.EDIT_CAISSE_OUTPUT_DOMAIN, UsersRolesAttributes.DELETE_CAISSE_OUTPUT_DOMAIN,]) && {
                    id: 'configs',
                    title: 'Configurations',
                    type: 'collapse',
                    icon: 'feather icon-settings info',
                    children: [
                        containsElements(userRoles, [UsersRolesAttributes.ADD_OR_EDIT_LABS, UsersRolesAttributes.DELETE_LABS]) && {
                            id: 'laboratories',
                            title: 'Laboratoires',
                            type: 'item',
                            url: '/admin/configs/laboratories',
                        },
                        containsElements(userRoles, [UsersRolesAttributes.ADD_OR_EDIT_PARTNERS, UsersRolesAttributes.DELETE_PARTNERS]) && {
                            id: 'partners',
                            title: 'Partenaires',
                            type: 'item',
                            url: '/admin/configs/partners',
                        },
                        containsElements(userRoles, [UsersRolesAttributes.ADD_CAISSE_OUTPUT_ORDONNATOR, UsersRolesAttributes.EDIT_CAISSE_OUTPUT_ORDONNATOR, UsersRolesAttributes.DELETE_CAISSE_OUTPUT_ORDONNATOR, UsersRolesAttributes.ADD_CAISSE_OUTPUT_DOMAIN, UsersRolesAttributes.EDIT_CAISSE_OUTPUT_DOMAIN, UsersRolesAttributes.DELETE_CAISSE_OUTPUT_DOMAIN]) && {
                            id: 'caisse',
                            title: 'Caisse',
                            type: 'item',
                            url: '/admin/configs/caisse',
                        },
                    ]
                },
                containsElements(userRoles, [UsersRolesAttributes.SEND_PUSH_NOTIFICATIONS]) && {
                    id: 'forms',
                    title: 'Notifications aux Utilisateurs',
                    type: 'item',
                    url: '/admin/push-notifications',
                    icon: 'feather icon-bell success'
                },
                containsElements(userRoles, [UsersRolesAttributes.EDIT_USER_PROFILE]) && {
                    id: 'forms',
                    title: 'Profiles',
                    type: 'item',
                    url: '/admin/profiles',
                    icon: 'feather icon-lock danger'
                },
                containsElements(userRoles, [UsersRolesAttributes.HANDLE_TAXES_MANAGEMENT]) && {
                    id: 'forms',
                    title: "Chiffre d'Affaires",
                    type: 'item',
                    url: '/admin/taxes',
                    icon: 'fas fa-money-check-alt warning'
                },
                containsElements(userRoles, [UsersRolesAttributes.CONSULT_HISTORIES]) && {
                    id: 'histories',
                    title: 'Historiques',
                    type: 'item',
                    url: '/admin/histories',
                    icon: 'feather icon-file primary'
                },
            ]
        },
    ];

    return menuItems
}
